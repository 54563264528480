import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import qs from 'query-string';
import Cookies from 'js-cookie';

import SettingsPage from './settings';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PDIcon from '../images/pd-icon.svg';
import { DEMO_APP_URL, HOST, APP_ID } from '../config';

const IndexPage = () => {
  let isConnected = false;
  let serviceList = '';
  let accountSubdomain = '';

  if (typeof document !== 'undefined') {
    const urlParams = qs.parse(window.location.search).config;
    let cookie = Cookies.getJSON('integrationParams');
    isConnected = !!(typeof (cookie) !== 'undefined' || typeof (urlParams) !== 'undefined');
    if (isConnected) {
      if (typeof (cookie) !== 'undefined') {
        Cookies.set('integrationParams', cookie);
      } else {
        Cookies.set('integrationParams', urlParams);
        cookie = Cookies.getJSON('integrationParams');
      }
      serviceList = cookie.integration_keys;
      accountSubdomain = cookie.account;
    }
  }

  const onConnect = () => {
    window.location.href = `https://app.${HOST}/install/integration?app_id=${APP_ID}&redirect_url=${DEMO_APP_URL}&version=2`;
  };

  return (
    <Layout>
      <SEO title="Home" />
      <div className="header">Integrations</div>
      {!isConnected ? (
        <center>
          <div className="connect">
            <img src={PDIcon} alt="PD Logo" />
            <div className="connect-header">Connect with PagerDuty</div>
            <p>You can manage alerts with your PagerDuty account.<br />Click the button below to integrate with your account.</p>
            <Button
              variant="info"
              size="lg"
              onClick={onConnect}
            >
              Connect
            </Button>
          </div>
        </center>
      )
        : (
          <SettingsPage
            serviceList={serviceList}
            accountSubdomain={accountSubdomain}
          />
        )}
    </Layout>
  );
};

export default IndexPage;
